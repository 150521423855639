import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { exportSaleStock, getPurchaseStock, updateStockInHand } from "../Product/Redux/productAction";
import { productActions } from "../Product/Redux/productSlice";

createTheme("dark", {
  background: {
    default: "transparent",
  },
  text: {
    primary: "primary",
    secondary: "secondary",
  },
});

const Purchase = () => {
  const { StockInHandDetail, column,StockInHandData } = useSelector((state) => state.Product);
  const [show,setShow] = useState(false);
  const [exportShow, setExportShow] = useState(false);
  const handleEditorField = (e,row,item) => {
    setShow(true);
    dispatch(productActions.updateStockInHand({id: row.id,item,value: e.target.value,product: row.name}))
  }
  
  console.log(StockInHandDetail);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    // {
    //   name: "Price",
    //   selector: (row) => <div>${row.price}</div>,
    //   sortable: true,
    // },
  ];

  const test = column
    ? column.map((item) => {
        return {
          name: item,
          selector: (row) => (
            <>
              <input
                className="text-center w-25"
                style={{ height: "30px" }}
                defaultValue={row[item]}
                onChange={(e) => handleEditorField(e,row,item)}
                // onChange={(e) => row[item] = e.target.value}
              />
              {/* <button className="btn btn-outline-info btn-sm delete" style={{marginLeft: '4px', height: '30px'}}><i className="fas fa-save"></i></button>
              <button className="btn btn-outline-danger btn-sm delete" style={{marginLeft: '4px', height: '30px'}}><i className="fa-times fas"></i></button> */}
            </>
          ),
          sortable: true,
          
        };
      })
    : [];

  const results = [...columns, ...test];
  console.log(results);
  console.log(StockInHandData,'tt');

  const dispatch = useDispatch();

  const handleDateRange = (e) => {
    if (e != null) {
      const starting = new Date(e[0]);
      const ending = new Date(e[1]);

      const startingDate = starting.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });
      const endingDate = ending.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });

      localStorage.setItem('starting_date',startingDate);
      localStorage.setItem('ending_date',endingDate);

      const data = new FormData();
      data.append("starting_date", startingDate);
      data.append("ending_date", endingDate);

      dispatch(getPurchaseStock(data));
      setExportShow(true);

      console.log(startingDate, endingDate);
    }
  };

  const exportData = () => {
    const data = new FormData();
    data.append("starting_date",localStorage.getItem('starting_date'));
    data.append("ending_date",localStorage.getItem('ending_date'))
    dispatch(exportSaleStock(data,'export-purchase-stock','purchase-stock-data ',localStorage.getItem('starting_date'),localStorage.getItem('ending_date')));
  }

  const handleSubmit = () => {
    const data = new FormData();
    data.append('data_value',JSON.stringify(StockInHandData))
    dispatch(updateStockInHand(data,1))
    setShow(false);
  }

  useEffect(() => {
    return () => {
      dispatch(productActions.resetData());
      localStorage.removeItem('starting_date');
      localStorage.removeItem('ending_date');
    };
  }, [dispatch]);

  //   useEffect(() => {
  //     dispatch(getAllProduct());
  //   }, [dispatch]);
  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="page-title-box d-sm-flex align-items-center
                    justify-content-between"
              >
                <h4 className="mb-sm-0 font-size-18">Purchase</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Purchase</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="justify-content-between d-flex">
                  <div className="d-flex align-items-center">
                    <DateRangePicker
                      format="MMM yyyy"
                      onChange={handleDateRange}
                    />
                  </div>
                  <div>
                    {show && <button
                      className="btn btn-success"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>}
                    {exportShow && <button
                        className="btn btn-success"
                        onClick={exportData}
                      >
                        Export
                      </button>}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={results}
                  data={StockInHandDetail ? StockInHandDetail : []}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {/* <CreateProduct modal={createModal} toggle={createToggle} />
      <UpdateProduct modal={updateModal} toggle={updateToggle} id={id}/>
      <DeleteProduct modal={deleteModal} toggle={deleteToggle} id={deleteId} /> */}
    </>
  );
};

export default Purchase;
