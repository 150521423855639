import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brands: null,
  selectedBrand: null,
  loading: false,
};

const brandSlice = createSlice({
  name: "Brand",
  initialState,
  reducers: {
    catchAction: (state, action) => {
      state.loading = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setSelectedBrand: (state, action) => {
      state.selectedBrand = action.payload;
    },
    storeBrand: (state, action) => {
      state.brands = [...state.brands, action.payload];
    },
    updateBrand: (state, action) => {
      state.brands =
        state.brands &&
        state.brands.map((obj) =>
          obj.id === action.payload?.id ? action.payload : obj
        );
    },
    deleteBrand: (state, action) => {
      state.brands =
        state.brands &&
        state.brands.filter((obj) => obj.id !== action.payload);
    },
    resetData: () => {
      return initialState;
    }
  },
});

export default brandSlice;

export const brandActions = brandSlice.actions;
