import React, { useEffect, useState } from "react";
import CustomModal from "../../../Modal";
import { useDispatch,useSelector } from "react-redux";
import { Form, Formik } from "formik";
import FormInputField from "../../../UI/FormInputField/Index";
import FormSelectField from "../../../UI/FormSelectField/Index";
import FormButtons from "../../../UI/FormButtons/Index";
import { createProduct, getBrand, getType } from "../Redux/productAction";

const formConfig = {
  Name: {
    type: "text",
    name: "name",
    label: "Name",
  },
  Price: {
    type: "number",
    name: "price",
    label: "Price",
  },
};

const CreateProduct = ({ toggle, modal }) => {
  const { brands,types } = useSelector((state) => state.Product);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "",
  };

  const handleSubmitForm = (values) => {
    setLoading(true);
    const data = new FormData();
    data.append("name", values.name);
    data.append("brand_id", values.brand_id.value);
    data.append("type_id", values.type_id.value);
    data.append("price", values.price);
    dispatch(createProduct(data)).then((res) => {
      if (res.data.status === true) {
        setLoading(false);
        toggle();
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(getBrand());
    dispatch(getType());
  },[dispatch])
  return (
    <CustomModal title="Add Product" toggle={toggle} modal={modal}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          }
          if (!values.brand_id) {
            errors.brand_id = "Required";
          }
          if (!values.type_id) {
            errors.type_id = "Required";
          }
          if (!values.price) {
            errors.price = "Required";
          }
          return errors;
        }}
        onSubmit={handleSubmitForm}
      >
        {({ setFieldValue, values, errors }) => (
          <>
            <Form role="form">
              <FormInputField {...formConfig["Name"]} />
              <FormSelectField
                options={brands ? brands : []}
                label="Brand"
                onChange={(selected) => setFieldValue("brand_id", selected)}
                value={values.brand_id}
                error={errors.brand_id}
              />
              <FormSelectField
                options={types ? types : []}
                label="Type"
                onChange={(selected) => setFieldValue("type_id", selected)}
                value={values.type_id}
                error={errors.type_id}
              />
              <FormInputField {...formConfig["Price"]} />
              <FormButtons toggle={toggle} loading={loading} />
            </Form>
          </>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateProduct;
