import { toast } from "react-toastify";
import axiosInstance from "../../../Http-Request/axios-instance";
import { typeActions } from "./typeSlice";


export const getAllType = () => async (dispatch) => {
  dispatch(typeActions.catchAction(true));
  try {
    const res = await axiosInstance.get("/type");
    dispatch(typeActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(typeActions.setTypes(res.data.data));
    }
    return res;
  } catch (error) {
    dispatch(typeActions.catchAction(false));
    return error.response;
  }
};

export const createType = (data) => async (dispatch) => {
  dispatch(typeActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/type", data);
    dispatch(typeActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(typeActions.storeType(res.data.data));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(typeActions.catchAction(false));
    return error.response;
  }
};

export const updateType = (data, id) => async (dispatch) => {
  dispatch(typeActions.catchAction(true));
  try {
    const res = await axiosInstance.post(
      `/type/${id}?_method=put`,
      data
    );
    dispatch(typeActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(typeActions.updateType(res.data.data));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(typeActions.catchAction(false));
    return error.response;
  }
};

export const getEditType = (id) => async (dispatch) => {
  dispatch(typeActions.catchAction(true));
  try {
    const res = await axiosInstance.get(`/type/${id}/edit`);
    dispatch(typeActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(typeActions.setSelectedType(res.data.data));
    }
    return res;
  } catch (error) {
    dispatch(typeActions.catchAction(false));
    return error.response;
  }
};

export const deleteType = (id) => async (dispatch) => {
  dispatch(typeActions.catchAction(true));
  try {
    const res = await axiosInstance.delete(`/type/${id}`);
    dispatch(typeActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(typeActions.deleteType(id));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(typeActions.catchAction(false));
    return error.response;
  }
};
