import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CustomModal from "../../../Modal";
import { getSaleReturn, returnSaleItem } from "../../Product/Redux/productAction";
import { Form, Formik } from "formik";
import FormInputField from "../../../UI/FormInputField/Index";
import FormButtons from "../../../UI/FormButtons/Index";

const formConfig = {
  Name: {
    type: "number",
    name: "name",
    label: "",
  },
};

const ReturnSale = ({ toggle, modal, id }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
  };

  const deleteHandle = (values) => {
    const data = new FormData();
    data.append("price", values.name);
    dispatch(returnSaleItem(data,id)).then((res) => {
      if (res.data.status === true) {
        const data = new FormData();
        data.append("starting_date", localStorage.getItem('st'));
        data.append("ending_date", localStorage.getItem('et'));
        dispatch(getSaleReturn(data))
      
        setLoading(false);
        toggle();
      } else {
        setLoading(false);
      }
    });
  };
  return (
    <CustomModal title="Return Sale" toggle={toggle} modal={modal}>
      <p>Are you sure you want to Return this Sale Item?</p>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          }
          return errors;
        }}
        onSubmit={deleteHandle}
      >
        {() => (
          <>
            <Form role="form">
              <FormInputField {...formConfig["Name"]} />
              <FormButtons toggle={toggle} loading={loading} btn={'delete'}/>
            </Form>
          </>
        )}
      </Formik>
      <div className="float-end mb-3">
        {/* <button
          type="button"
          className="btn btn-dark"
          onClick={toggle}
          style={{ marginRight: "8px" }}
        >
          Close
        </button> */}
        {/* <button onClick={deleteHandle} className="btn btn-danger">
          {loading && (
            <Spinner
              style={{ marginRight: "5px" }}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Delete
        </button> */}
      </div>
    </CustomModal>
  );
};

export default ReturnSale;
