import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CustomModal from "../../../Modal";
import { Spinner } from "reactstrap";
import { deleteProduct } from "../Redux/productAction";

const DeleteProduct = ({ toggle, modal, id }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const deleteHandle = () => {
    dispatch(deleteProduct(id)).then((res) => {
      if (res.data.status === true) {
        setLoading(false);
        toggle();
      } else {
        setLoading(false);
      }
    });
  };
  return (
    <CustomModal title="Delete Product" toggle={toggle} modal={modal}>
      <p>Are you sure you want to delete this Product?</p>
      <div className="float-end mb-3">
        <button
          type="button"
          className="btn btn-dark"
          onClick={toggle}
          style={{ marginRight: "8px" }}
        >
          Close
        </button>
        <button onClick={deleteHandle} className="btn btn-danger">
          {loading && (
            <Spinner
              style={{ marginRight: "5px" }}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Delete
        </button>
      </div>
    </CustomModal>
  );
};

export default DeleteProduct;
