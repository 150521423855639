import { toast } from "react-toastify";
import axiosInstance from "../../../Http-Request/axios-instance";
import { productActions } from "./productSlice";

export const getAllProduct = () => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.get("/product");
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(productActions.setProducts(res.data.data));
    }
    return res;
  } catch (error) {
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getBrand = () => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.get("/getBrand");
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(productActions.setBrand(res.data.data));
    }
    return res;
  } catch (error) {
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getType = () => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.get("/getType");
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(productActions.setType(res.data.data));
    }
    return res;
  } catch (error) {
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const createProduct = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/product", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(productActions.storeProduct(res.data.data));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getStockInHand = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/stock-in-hand", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: res.data.dating,
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getSaleReport = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/sale-report", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: res.data.dating,
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getPurchaseReport = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/purchase-report", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: res.data.dating,
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getCOGSReport = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/cogs-report", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: res.data.summary,
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getSaleReturn = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/sale-return", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: [],
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getPurchaseReturn = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/purchase-return", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: [],
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getPurchaseStock = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/purchase-stock", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: res.data.dating,
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getSaleStock = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/sale-stock", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: res.data.dating,
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getStockPurchase = (data) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/stock-purchase", data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      console.log(res.data.data);

      dispatch(
        productActions.setStockInHand({
          value: res.data.dating,
          data: res.data.data,
          column: res.data.column,
        })
      );
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const exportSaleStock = (data,endpoint,name,s,e) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance
      .post("/"+endpoint, data, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name+s+" - "+e+".csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading CSV:", error));
    dispatch(productActions.catchAction(false));

    return res;
  } catch (error) {
    //toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const updateStockInHand = (data, id) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post(
      `/stock-in-hand/${id}?_method=put`,
      data
    );
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      //dispatch(productActions.setStockInHand({data: res.data.data, column: res.data.column}));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const updateSaleStock = (data, id) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post(`/sale-stock/${id}?_method=put`, data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      //dispatch(productActions.setStockInHand({data: res.data.data, column: res.data.column}));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    //console.log(error.response);
    toast.error(error.response.data.message);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const updatePurchaseStock = (data, id) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post(`/stock-update-purchase`, data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      //dispatch(productActions.setStockInHand({data: res.data.data, column: res.data.column}));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    //console.log(error.response);
    toast.error(error.response.data.message);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const updateProduct = (data, id) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post(`/product/${id}?_method=put`, data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(productActions.updateProduct(res.data.data));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const getEditProduct = (id) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.get(`/product/${id}/edit`);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(productActions.setSelectedProduct(res.data.data));
    }
    return res;
  } catch (error) {
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.delete(`/product/${id}`);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(productActions.deleteProduct(id));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};

export const returnSaleItem = (data,id) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post(`/return-sale/${id}`,data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      //dispatch(productActions.deleteStock({id,data}));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};
export const returnPurchaseItem = (data,id) => async (dispatch) => {
  dispatch(productActions.catchAction(true));
  try {
    const res = await axiosInstance.post(`/return-purchase/${id}`,data);
    dispatch(productActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(productActions.deleteStock(id));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(productActions.catchAction(false));
    return error.response;
  }
};
