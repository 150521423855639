import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const CustomModal = ({modal,toggle,children,title}) => {
  return (
    <Modal isOpen={modal} toggle={toggle} centered={true} size="md" >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default CustomModal;