import React, { useEffect } from "react";
import Layout from "../Layout";
import DataTable, { createTheme } from "react-data-table-component";
import UseModel from "../../hooks/use-Model";
import { useDispatch, useSelector } from "react-redux";
import CreateType from "./Form/Create";
import UpdateType from "./Form/Update";
import DeleteType from "./Form/Delete";
import { getAllType, getEditType } from "./Redux/typeAction";

createTheme('dark', {
  background: {
    default: 'transparent',
  },
  text: {
    primary: "primary",
    secondary: 'secondary',
  }
});




const Type = () => {
  const { types } = useSelector((state) => state.Type);
  const { user } = useSelector((state) => state.Auth);
  const {modal: createModal, toggle: createToggle} = UseModel();
  const {modal: updateModal, toggle: updateToggle,updateHandle,id} = UseModel();
  const {modal: deleteModal, toggle: deleteToggle,deleteHandle,id: deleteId} = UseModel();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
     {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <button className="btn btn-outline-secondary btn-sm edit m-1" onClick={() => updateHandle(getEditType,row?.id)} title="Edit">
              <i className="fas fa-pencil-alt"></i>
            </button>
            <button className="btn btn-outline-danger btn-sm delete" onClick={() => deleteHandle(row?.id)} title="cancel">
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        );
      },
    },
    
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllType())
  },[dispatch]);
  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="page-title-box d-sm-flex align-items-center
                    justify-content-between"
              >
                <h4 className="mb-sm-0 font-size-18">Type</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Type</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="justify-content-between d-flex">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">Type</h4>
                  </div>
                  <div>
                     <button
                      className="btn btn-light"
                      onClick={createToggle}
                    >
                      <i className="bx bx-plus me-1"></i>
                      Add New
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable columns={columns} data={types ? types : []} pagination paginationPerPage={50} paginationRowsPerPageOptions={[10, 30, 50]} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <CreateType modal={createModal} toggle={createToggle} />
      <UpdateType modal={updateModal} toggle={updateToggle} id={id}/>
      <DeleteType modal={deleteModal} toggle={deleteToggle} id={deleteId} />
    </>
  );
};

export default Type;

