import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import $ from "jquery";
// import { Link } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { logoutUser } from "../../Auth/Redux/authAction";
// import LogoutModal from "../Modal/ProfileModal/LogoutModal";

const Header = () => {
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
//   const { institute } = useSelector((state) => state.Institute);
  const [currentSidebarSize, setCurrentSidebarSize] = useState(null);

  useEffect(() => {
    const handleWindowLoad = () => {
      if (window.innerWidth >= 1024 && window.innerWidth <= 1366) {
        document.body.setAttribute("data-sidebar-size", "sm");
      }
    };

    const handleSwitchChange = () => {
      // eslint-disable-next-line no-undef
      toggleWeather();
    };

    $(window).on("load", handleWindowLoad);
    $(".switch").on("switch-change", handleSwitchChange);

    return () => {
      $(window).off("load", handleWindowLoad);
      $(".switch").off("switch-change", handleSwitchChange);
    };
  }, []);

  const handleVerticalMenuBtnClick = (event) => {
    event.preventDefault();
    setCurrentSidebarSize();
    $("body").toggleClass("sidebar-enable");
    if ($(window).width() >= 992) {
      if (currentSidebarSize === null) {
        document.body.getAttribute("data-sidebar-size") === null ||
        document.body.getAttribute("data-sidebar-size") === "lg"
          ? document.body.setAttribute("data-sidebar-size", "sm")
          : document.body.setAttribute("data-sidebar-size", "lg");
      } else if (currentSidebarSize === "md") {
        document.body.getAttribute("data-sidebar-size") === "md"
          ? document.body.setAttribute("data-sidebar-size", "sm")
          : document.body.setAttribute("data-sidebar-size", "md");
      } else {
        document.body.getAttribute("data-sidebar-size") === "sm"
          ? document.body.setAttribute("data-sidebar-size", "lg")
          : document.body.setAttribute("data-sidebar-size", "sm");
      }
    } else {
      // $('body').removeClass('vertical-collpsed');
      // document.body.removeAttribute('data-sidebar-size')
    }
  };

  return (
    <div>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* <!-- LOGO --> */}
            <div className="navbar-brand-box">
              <a href="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src={
                      "/assets/images/logo-sm.svg"
                    }
                    alt=""
                    height="24"
                    width="24"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={
                       "/assets/images/logo-sm.svg"
                    }
                    alt=""
                    height="24"
                    width="24"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  ></img>
                  <span className="logo-txt">Include</span>
                </span>
              </a>

              <a href="/" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={
                      "/assets/images/logo-sm.svg"
                    }
                    alt=""
                    height="24"
                    width="24"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={
                       "/assets/images/logo-sm.svg"
                    }
                    alt=""
                    height="24"
                    width="24"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />{" "}
                  <span className="logo-txt">Include</span>
                </span>
              </a>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
              onClick={handleVerticalMenuBtnClick}
            >
              <Icon.Menu />
            </button>

            {/* <!-- App Search--> */}
            <form className="app-search d-none d-lg-block">
              {/* <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <button className="btn btn-primary" type="button">
                  <i className="bx bx-search-alt align-middle"></i>
                </button>
              </div> */}
            </form>
          </div>

          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i data-feather="search" className="icon-lg"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        aria-label="Search Result"
                      />

                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}

            {/* <button
              type="button"
              className="btn header-item"
              id="mode-setting-btn"
              onClick={changeThemeColor}
            >
              {theme ? <Icon.Moon /> : <Icon.Sun />}
            </button> */}

            {/* <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
              <Icon.Grid/>
              </button>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
                <div className="p-2">
                  <div className="row g-0">
                    <div className="col">
                      <a className="dropdown-icon-item" href="#">
                        <img
                          src="/assets/images/brands/github.png"
                          alt="Github"
                        />
                        <span>GitHub</span>
                      </a>
                    </div>
                    <div className="col">
                      <a className="dropdown-icon-item" href="#">
                        <img
                          src="/assets/images/brands/bitbucket.png"
                          alt="bitbucket"
                        />
                        <span>Bitbucket</span>
                      </a>
                    </div>
                    <div className="col">
                      <a className="dropdown-icon-item" href="#">
                        <img
                          src="/assets/images/brands/dribbble.png"
                          alt="dribbble"
                        />
                        <span>Dribbble</span>
                      </a>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col">
                      <a className="dropdown-icon-item" href="#">
                        <img
                          src="/assets/images/brands/dropbox.png"
                          alt="dropbox"
                        />
                        <span>Dropbox</span>
                      </a>
                    </div>
                    <div className="col">
                      <a className="dropdown-icon-item" href="#">
                        <img
                          src="/assets/images/brands/mail_chimp.png"
                          alt="mail_chimp"
                        />
                        <span>Mail Chimp</span>
                      </a>
                    </div>
                    <div className="col">
                      <a className="dropdown-icon-item" href="#">
                        <img
                          src="/assets/images/brands/slack.png"
                          alt="slack"
                        />
                        <span>Slack</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon position-relative"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Icon.Bell />
                <span className="badge bg-danger rounded-pill">{popupUnreadCount}</span>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
              >
                <div className="p-3 border-bottom">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="m-0"> Notifications</h6>
                    </div>
                    <div className="col-auto">
                      <Link
                        to="/notification/inbox"
                        className="small text-reset text-decoration-underline"
                      >
                        Unread ({popupUnreadCount})
                      </Link>
                    </div>
                  </div>
                </div>
                <div style={{ maxHeight: "260px" }}>
                  {popUpNotification &&
                    popUpNotification.map((item) => {
                      return (
                        <>
                          <Link
                            to={`/notification/read/${item.id}`}
                            className="text-reset notification-item"
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <img
                                  src={item.user?.image ? item.user?.image : "/assets/images/users/avatar-3.jpg"}
                                  className="rounded-circle avatar-sm"
                                  alt="user-pic"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <h6 className="mb-1">{item.title}</h6>
                                <div className="font-size-13 text-muted" style={!item.read ? {fontWeight: "bold"} : {}}>
                                  <p
                                    className="mb-1"
                                    style={{
                                      maxWidth: "240px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item.body}
                                  </p>
                                  <p className="mb-0">
                                    <i className="mdi mdi-clock-outline"></i>{" "}
                                    <span>{item.time}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>
                      );
                    })}
                </div>
                <div className="p-2 border-top d-grid">
                  <Link
                    className="btn btn-sm btn-link font-size-14 text-center"
                    to="/notification/inbox"
                  >
                    <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                    <span>View_More</span>
                  </Link>
                </div>
              </div>
            </div> */}

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item right-bar-toggle me-2"
              >
                <i data-feather="settings" className="icon-lg"></i>
              </button>
            </div>

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item bg-soft-light border-start border-end"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={
                    user?.image
                      ? user?.image
                      : "/assets/images/users/avatar-1.jpg"
                  }
                  alt="Header Avatar"
                />
                <span className="d-none d-xl-inline-block ms-1 fw-medium">
                  {user?.name}
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <!-- item--> */}
                {/* <Link className="dropdown-item" to="/updateProfile">
                  <i className="mdi mdi-face-profile font-size-16 align-middle me-1"></i>{" "}
                  Profile
                </Link>
                <Link className="dropdown-item" to="/changePassword">
                  <i className="mdi mdi-lock font-size-16 align-middle me-1"></i>{" "}
                  Change Password
                </Link> */}
                {/* <button className="dropdown-item" onClick={handleEmailConfig}>
                  <i className="mdi mdi-lock font-size-16 align-middle me-1"></i>{" "}
                  Email Config
                </button> */}
                {/* <div className="dropdown-divider"></div> */}
                
                <button
                  className="dropdown-item"
                  onClick={() => dispatch(logoutUser())}
                >
                  <i className="mdi mdi-logout font-size-16 align-middle me-1"></i>{" "}
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <LogoutModal /> */}
    </div>
  );
};

export default Header;
