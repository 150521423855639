import React, { useEffect } from "react";
import Layout from "../Layout";
import DataTable, { createTheme } from "react-data-table-component";
import UseModel from "../../hooks/use-Model";
import { useDispatch, useSelector } from "react-redux";
import CreateProduct from "./Form/Create";
import UpdateProduct from "./Form/Update";
import DeleteProduct from "./Form/Delete";
import { getAllProduct, getEditProduct } from "./Redux/productAction";

// import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

createTheme('dark', {
  background: {
    default: 'transparent',
  },
  text: {
    primary: "primary",
    secondary: 'secondary',
  }
});


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="text"
      style={{ height: "37px" }}
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button className="btn btn-info m-1" type="button" onClick={onClear}>
      Remove
    </button>
  </>
);




const Product = () => {
  const { products } = useSelector((state) => state.Product);
  const { user } = useSelector((state) => state.Auth);
  const [pending, setPending] = React.useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const {modal: createModal, toggle: createToggle} = UseModel();
  const {modal: updateModal, toggle: updateToggle,updateHandle,id} = UseModel();
  const {modal: deleteModal, toggle: deleteToggle,deleteHandle,id: deleteId} = UseModel();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => row.brand.name,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => <div>${row.price}</div>,
      sortable: true,
    },
    
     {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <button className="btn btn-outline-secondary btn-sm edit m-1" onClick={() => updateHandle(getEditProduct,row?.id)} title="Edit">
              <i className="fas fa-pencil-alt"></i>
            </button>
            <button className="btn btn-outline-danger btn-sm delete" onClick={() => deleteHandle(row?.id)} title="cancel">
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        );
      },
    },
    
  ];

  const filteredItems =
  products &&
  products.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

  const dispatch = useDispatch();

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    setPending(true);
    dispatch(getAllProduct()).then((res) => {
      setPending(false);
    });
  },[dispatch]);
  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="page-title-box d-sm-flex align-items-center
                    justify-content-between"
              >
                <h4 className="mb-sm-0 font-size-18">Product</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Product</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="justify-content-between d-flex">
                  <div className="d-flex align-items-center">
                  {/* <DateRangePicker format="MMM yyyy" /> */}
                  </div>
                  <div>
                  
                    <button
                      className="btn btn-light"
                      onClick={createToggle}
                    >
                      <i className="bx bx-plus me-1"></i>
                      Add New
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable columns={columns} data={filteredItems ? filteredItems : []} pagination paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  paginationPerPage={50}
                  progressPending={pending}
                  paginationRowsPerPageOptions={[10, 30, 50]}
                  paginationComponentOptions={paginationComponentOptions}/>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <CreateProduct modal={createModal} toggle={createToggle} />
      <UpdateProduct modal={updateModal} toggle={updateToggle} id={id}/>
      <DeleteProduct modal={deleteModal} toggle={deleteToggle} id={deleteId} />
    </>
  );
};

export default Product;

