import { ErrorMessage, useField } from "formik";
import React from "react";

const FormInputField = (props) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-3">
      <label htmlFor={props.name} className="form-label">
        {props.label}
      </label>
      <input 
        className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""}`}
        {...field}
        {...props}
        onKeyDown={(e) => {
          if (props.type === "number") {
            (e.key === "e" || e.key === "-") && e.preventDefault();
          }
        }}
        autoComplete={props.autoComplete || "off"}
      />
      <ErrorMessage
        component="div"
        name={field.name}
        className="m-1 text-danger"
      />
    </div>
  );
};

export default FormInputField;
