import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { exportSaleStock, getCOGSReport } from "../Product/Redux/productAction";
import { productActions } from "../Product/Redux/productSlice";

createTheme("dark", {
  background: {
    default: "transparent",
  },
  text: {
    primary: "primary",
    secondary: "secondary",
  },
});

const COGSReport = () => {
  const { StockInHandDetail, column, StockInHandData } = useSelector(
    (state) => state.Product
  );
  const [exportShow, setExportShow] = useState(false);
  const dispatch = useDispatch();

  const columns = ["Name", "Brand", "Type"].map((item) => {
    return {
      name: item,
      selector: (row) => row[item.toLowerCase()],
      sortable: true,
    };
  });

  const columns1 = [
    {
      name: "Name",
      selector: (row) => "COGS",
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => "",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => "",
      sortable: true,
    },
  ];

  const test = column
    ? column.map((item) => {
        return {
          name: item,
          selector: (row) => <div>${row[item] ?? 0}</div>,
          sortable: true,
        };
      })
    : [];

  const results = [...columns, ...test];
  const result1 = [...columns1, ...test];

  const handleDateRange = (e) => {
    if (e != null) {
      const dates = e.map((date) =>
        new Date(date)?.toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        })
      );
      const data = new FormData();
      data.append("starting_date", dates[0]);
      data.append("ending_date", dates[1]);
      localStorage.setItem('starting_date',dates[0]);
      localStorage.setItem('ending_date',dates[1]);
      dispatch(getCOGSReport(data));
      setExportShow(true);
    }
  };
  const exportData = () => {
    const data = new FormData();
    data.append("starting_date", localStorage.getItem("starting_date"));
    data.append("ending_date", localStorage.getItem("ending_date"));
    dispatch(
      exportSaleStock(
        data,
        "export-cogs-report",
        "cogs-report ",
        localStorage.getItem("starting_date"),
        localStorage.getItem("ending_date")
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(productActions.resetData());
    };
  }, [dispatch]);

  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">COGS Report</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Report</li>
                  </ol>
                </div>
              </div>
            </div>
            <CardComponent
              title="COGS Report"
              handleDateRange={handleDateRange}
              exportData={exportData}
              data={StockInHandDetail}
              columns={results}
              exportShow= {exportShow}
            />
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">COGS Summary</h4>
            </div>
            <CardComponent
              title="COGS Summary"
              handleDateRange={handleDateRange}
              exportData={exportData}
              data={StockInHandData}
              columns={result1}
              exportShow= {exportShow}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

const CardComponent = ({
  title,
  handleDateRange,
  exportData,
  data,
  exportShow,
  columns,
}) => {
  
  return (
    <div className="col-12">
      <div className="card">
        {title === "COGS Report" && (
          <div className="card-header">
            <div className="justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <DateRangePicker format="MMM yyyy" onChange={handleDateRange} />
              </div>
              <div>
                {exportShow && (
                  <button className="btn btn-success" onClick={exportData}>
                    Export
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="card-body">
          <DataTable columns={columns} data={data ?? []} pagination />
        </div>
      </div>
    </div>
  );
};

export default COGSReport;
