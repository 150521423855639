import React, { useState } from "react";
import CustomModal from "../../../Modal";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import FormInputField from "../../../UI/FormInputField/Index";
import FormButtons from "../../../UI/FormButtons/Index";
import { createBrand } from "../Redux/brandAction";

const formConfig = {
  Name: {
    type: "text",
    name: "name",
    label: "Name",
  },
};

const CreateBrand = ({ toggle, modal }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "",
  };

  const handleSubmitForm = (values) => {
    setLoading(true);
    const data = new FormData();
    data.append("name", values.name);
    dispatch(createBrand(data)).then((res) => {
      if (res.data.status === true) {
        setLoading(false);
        toggle();
      } else {
        setLoading(false);
      }
    });
  };
  return (
    <CustomModal title="Add Brand" toggle={toggle} modal={modal}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          }
          return errors;
        }}
        onSubmit={handleSubmitForm}
      >
        {() => (
          <>
            <Form role="form">
              <FormInputField {...formConfig["Name"]} />
              <FormButtons toggle={toggle} loading={loading} />
            </Form>
          </>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateBrand;
