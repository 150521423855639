import React from "react";
import Select from "react-select";
import "./index.scss";

const FormSelectField = ({
  options,
  label,
  onChange,
  value,
  error,
  notification = false,
}) => {
  return (
    <div className={notification ? "" : "mb-3"}>
      {!notification && <label className="form-label">{label}</label>}
      <Select
        options={options}
        onChange={onChange}
        value={value}
        isSearchable={true}
        menuPosition="fixed"
      />
      <p className="m-1 text-danger">{error}</p>
    </div>
  );
};

export default FormSelectField;
