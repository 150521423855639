import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: null,
  selectedProduct: null,
  StockInHandDetail: null,
  StockInHandData: null,
  column: null,
  brands: null,
  types: null,
  loading: false,
};

const productSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    catchAction: (state, action) => {
      state.loading = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setStockInHand: (state, action) => {
      console.log(action.payload.data, "sdsd");
      state.StockInHandDetail = action.payload.data;
      state.StockInHandData = action.payload.value;
      state.column = action.payload.column;
    },
    storeProduct: (state, action) => {
      state.products = [...state.products, action.payload];
    },
    setBrand: (state, action) => {
      state.brands = action.payload;
    },
    setType: (state, action) => {
      state.types = action.payload;
    },
    updateStockInHand: (state, action) => {
      state.StockInHandDetail = state.StockInHandDetail.map((item) =>
        item.id === action.payload.id
          ? { ...item, [action.payload.item]: action.payload.value }
          : item
      );
      state.StockInHandData = state.StockInHandData.map((item) =>
        item.stock_date === action.payload.item &&
        item.name === action.payload.product
          ? { ...item, total_stock: action.payload.value }
          : item
      );
    },
    updateSaleStock: (state,action) => {
      state.StockInHandDetail = state.StockInHandDetail.map((item) =>
        item.id === action.payload.id
          ? { ...item, [action.payload.item]: action.payload.value,total: item.total +  parseInt(action.payload.prev) - parseInt(action.payload.value) }
          : item
      );
      state.StockInHandData = state.StockInHandData.map((item) =>
        item.sale_date === action.payload.item &&
        item.name === action.payload.product
          ? { ...item, total_sale_item: action.payload.value }
          : item
      );
    },
    updatePurchaseStock: (state,action) => {
      state.StockInHandDetail = state.StockInHandDetail.map((item) =>
        item.id === action.payload.id
          ? { ...item, [action.payload.item]: action.payload.value,total: item.total -  parseInt(action.payload.prev) + parseInt(action.payload.value) }
          : item
      );
      state.StockInHandData = state.StockInHandData.map((item) =>
        item.sale_date === action.payload.item &&
        item.name === action.payload.product
          ? { ...item, total_sale_item: action.payload.value }
          : item
      );
    },
    updateProduct: (state, action) => {
      state.products =
        state.products &&
        state.products.map((obj) =>
          obj.id === action.payload?.id ? action.payload : obj
        );
    },
    deleteProduct: (state, action) => {
      state.products =
        state.products &&
        state.products.filter((obj) => obj.id !== action.payload);
    },
    deleteStock: (state, action) => {
      // state.StockInHandDetail =
      //   state.StockInHandDetail &&
      //   state.StockInHandDetail.map((obj) => obj.id === action.payload.id ? {...obj,total_sale_item : obj.total_sale_item -  parseInt(action.payload.data)} : obj);
    },
    resetData: () => {
      return initialState;
    },
  },
});

export default productSlice;

export const productActions = productSlice.actions;
