import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../components/Auth/Redux/authSlice";
import brandSlice from "../components/Brand/Redux/brandSlice";
import typeSlice from "../components/Type/Redux/typeSlice";
import productSlice from "../components/Product/Redux/productSlice";



const store  = configureStore({
    reducer : {
        Auth: authSlice.reducer,
        Brand: brandSlice.reducer,
        Type: typeSlice.reducer,
        Product: productSlice.reducer
    }
})

export default store;