import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import VerifyAuth from "../Auth/VerifyAuth";

const Layout = (props) => {
  return (
    <>
    <VerifyAuth>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />

        <div className="main-content">
          <div className="page-content">
            {props.children}
          </div>
          <Footer />
        </div>
      </div>
      </VerifyAuth>
    </>
  );
};

export default Layout;