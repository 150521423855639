import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {
  exportSaleStock,
  getSaleStock,
  getStockPurchase,
  updatePurchaseStock,
  updateSaleStock,
} from "../Product/Redux/productAction";
import { productActions } from "../Product/Redux/productSlice";
import FormSelectField from "../../UI/FormSelectField/Index";

createTheme("dark", {
  background: {
    default: "transparent",
  },
  text: {
    primary: "primary",
    secondary: "secondary",
  },
});

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="text"
      style={{ height: "37px" }}
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button className="btn btn-info m-1" type="button" onClick={onClear}>
      Remove
    </button>
  </>
);

const DataEntry = () => {
  const { StockInHandDetail, column, StockInHandData } = useSelector(
    (state) => state.Product
  );
  const [pending, setPending] = React.useState(false);

  const [date, setDate] = React.useState(false);

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [show, setShow] = useState(false);
  const [type, setType] = React.useState({ label: "Sale", value: "sale" });
  const [exportShow, setExportShow] = useState(false);
  const handleEditorField = (e, row, item) => {
    if (e.target.value) {
      setShow(true);
      if (type.value === "purchase") {
        dispatch(
          productActions.updatePurchaseStock({
            id: row.id,
            item,
            prev: row[item],
            value: e.target.value,
            product: row.name,
          })
        );
      } else if(type.value === "sale") {
        dispatch(
          productActions.updateSaleStock({
            id: row.id,
            item,
            prev: row[item],
            value: e.target.value,
            product: row.name,
          })
        );
      }
      
    } else {
      setShow(false);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Stock in Hand",
      selector: (row) => row.total,
      sortable: true,
    },
  ];

  const test = column
    ? column.map((item) => {
        return {
          name: item,
          selector: (row) => (
            <>
              <input
                className="text-center"
                style={{ height: "30px", width: "30px" }}
                defaultValue={row[item]}
                onChange={(e) => handleEditorField(e, row, item)}
              />
            </>
          ),
          sortable: true,
        };
      })
    : [];

  const results = [...columns, ...test];

  const dispatch = useDispatch();

  const handleDateRange = (e) => {
    if (e != null) {
      setPending(true);
      console.log(e, "tetet");
      const starting = new Date(e[0]);
      const ending = new Date(e[1]);

      const startingDate = starting.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const endingDate = ending.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      localStorage.setItem("starting_date", startingDate);
      localStorage.setItem("ending_date", endingDate);

      const data = new FormData();
      data.append("starting_date", startingDate);
      data.append("ending_date", endingDate);
      dispatch(getSaleStock(data)).then((res) => {
        setPending(false);
      });
      setExportShow(true);
      console.log(startingDate, endingDate);
    }
  };

  const exportData = () => {
    const data = new FormData();
    data.append("starting_date", localStorage.getItem("starting_date"));
    data.append("ending_date", localStorage.getItem("ending_date"));
    if (type.value === "purchase") {
      dispatch(
        exportSaleStock(
          data,
          "export-purchase-stock",
          "purchase stock ",
          localStorage.getItem("starting_date"),
          localStorage.getItem("ending_date")
        )
      );
    } else {
    dispatch(
      exportSaleStock(
        data,
        "export-sale-stock",
        "sale stock ",
        localStorage.getItem("starting_date"),
        localStorage.getItem("ending_date")
      )
    );
      }
  };

  const handleSubmit = () => {
    const data = new FormData();
    data.append("data_value", JSON.stringify(StockInHandData));
    
    if (type.value === "purchase") {
      dispatch(updatePurchaseStock(data, 1));
    } else if(type.value === "sale") {
      dispatch(updateSaleStock(data, 1));
    }
    setShow(false);
    handleType(type);
  };

  const filteredItems =
    StockInHandDetail &&
    StockInHandDetail.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

  useEffect(() => {
    const today = new Date();

    // Add three days to today's date
    const threeDaysLater = new Date(today);
    threeDaysLater.setDate(threeDaysLater.getDate() + 3);

    console.log(today, " == ", threeDaysLater);
    setDate([today, threeDaysLater]);
    handleDateRange([today, threeDaysLater]);
    return () => {
      dispatch(productActions.resetData());
      localStorage.removeItem("starting_date");
      localStorage.removeItem("ending_date");
    };
  }, [dispatch]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const handleType = (selected) => {
    setType(selected);
    const data = new FormData();
    data.append("starting_date", localStorage.getItem("starting_date"));
    data.append("ending_date", localStorage.getItem("ending_date"));
    if (selected.value === "purchase") {
      setPending(true);
      dispatch(getStockPurchase(data)).then((res) => {
        setPending(false);
      });
    } else if(selected.value === "sale") {
      setPending(true);
      dispatch(getSaleStock(data)).then((res) => {
        setPending(false);
      });
    }
    console.log(selected.value);
  };
  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="page-title-box d-sm-flex align-items-center
                    justify-content-between"
              >
                <h4 className="mb-sm-0 font-size-18">Data Entry</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Data Entry</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="justify-content-between d-flex">
                  <div className="d-flex align-items-center">
                    <DateRangePicker
                      value={date}
                      format="dd MMM yyyy"
                      onChange={handleDateRange}
                    />
                    <div style={{ width: "16rem", marginLeft: "8px" }}>
                      <FormSelectField
                        value={type}
                        options={[
                          { label: "Sale", value: "sale" },
                          { label: "Purchase", value: "purchase" },
                        ]}
                        onChange={(selected) => handleType(selected)}
                      />
                    </div>
                  </div>

                  <div>
                    {show && (
                      <button
                        className="btn btn-success"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    )}
                    {exportShow && (
                      <button className="btn btn-success" onClick={exportData}>
                        Export
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={results}
                  data={filteredItems ? filteredItems : []}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  paginationPerPage={50}
                  progressPending={pending}
                  paginationRowsPerPageOptions={[10, 30, 50]}
                  paginationComponentOptions={paginationComponentOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DataEntry;
