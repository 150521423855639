import { toast } from "react-toastify";
import axiosInstance from "../../../Http-Request/axios-instance";
import { brandActions } from "./brandSlice";

export const getAllBrand = () => async (dispatch) => {
  dispatch(brandActions.catchAction(true));
  try {
    const res = await axiosInstance.get("/brand");
    dispatch(brandActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(brandActions.setBrands(res.data.data));
    }
    return res;
  } catch (error) {
    dispatch(brandActions.catchAction(false));
    return error.response;
  }
};

export const createBrand = (data) => async (dispatch) => {
  dispatch(brandActions.catchAction(true));
  try {
    const res = await axiosInstance.post("/brand", data);
    dispatch(brandActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(brandActions.storeBrand(res.data.data));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(brandActions.catchAction(false));
    return error.response;
  }
};

export const updateBrand = (data, id) => async (dispatch) => {
  dispatch(brandActions.catchAction(true));
  try {
    const res = await axiosInstance.post(
      `/brand/${id}?_method=put`,
      data
    );
    dispatch(brandActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(brandActions.updateBrand(res.data.data));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(brandActions.catchAction(false));
    return error.response;
  }
};

export const getEditBrand = (id) => async (dispatch) => {
  dispatch(brandActions.catchAction(true));
  try {
    const res = await axiosInstance.get(`/brand/${id}/edit`);
    dispatch(brandActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(brandActions.setSelectedBrand(res.data.data));
    }
    return res;
  } catch (error) {
    dispatch(brandActions.catchAction(false));
    return error.response;
  }
};

export const deleteBrand = (id) => async (dispatch) => {
  dispatch(brandActions.catchAction(true));
  try {
    const res = await axiosInstance.delete(`/brand/${id}`);
    dispatch(brandActions.catchAction(false));
    if (res.data.status === true) {
      dispatch(brandActions.deleteBrand(id));
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.response.data.errors);
    dispatch(brandActions.catchAction(false));
    return error.response;
  }
};
