import React, { useEffect } from "react";
import Layout from "../Layout";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { getPurchaseReturn } from "../Product/Redux/productAction";
import { productActions } from "../Product/Redux/productSlice";
import UseModel from "../../hooks/use-Model";
import ReturnSale from "./Form/Delete";
// import { productActions } from "../Product/Redux/productSlice";

createTheme("dark", {
  background: {
    default: "transparent",
  },
  text: {
    primary: "primary",
    secondary: "secondary",
  },
});

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="text"
      style={{ height: "37px" }}
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button className="btn btn-info m-1" type="button" onClick={onClear}>
      Remove
    </button>
  </>
);

const PurchaseReturn = () => {
  const { StockInHandDetail } = useSelector((state) => state.Product);
  const [pending, setPending] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const {modal: deleteModal, toggle: deleteToggle,deleteHandle,id: deleteId} = UseModel();
  
  console.log(StockInHandDetail);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => <div>${row.price}</div>,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => <div>{row.sale_date}</div>,
      sortable: true,
    },
    {
      name: "Total Item",
      selector: (row) => <div>{row.total_sale_item}</div>,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="d-flex">

            <button className="btn btn-outline-secondary btn-sm edit m-1" onClick={() => deleteHandle(row?.id)} title="Edit">
              <i className="fas fa-pencil-alt"></i>
            </button>
          </div>
        );
      },
    },
  ];
  

  const dispatch = useDispatch();

  const handleDateRange = (e) => {
    if (e != null) {
      setPending(true);
      const starting = new Date(e[0]);
      const ending = new Date(e[1]);

      const startingDate = starting.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });
      const endingDate = ending.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });

      const data = new FormData();
      data.append("starting_date", startingDate);
      data.append("ending_date", endingDate);

      localStorage.setItem('st',startingDate);
      localStorage.setItem('et',endingDate);

      dispatch(getPurchaseReturn(data)).then((res) => {
        setPending(false);
      });

      console.log(startingDate, endingDate);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(productActions.resetData());
    };
  }, [dispatch]);


  const filteredItems =
    StockInHandDetail &&
    StockInHandDetail.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  //   useEffect(() => {
  //     dispatch(getAllProduct());
  //   }, [dispatch]);
  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="page-title-box d-sm-flex align-items-center
                    justify-content-between"
              >
                <h4 className="mb-sm-0 font-size-18">Purchase Return</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Report</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="justify-content-between d-flex">
                  <div className="d-flex align-items-center">
                    <DateRangePicker
                      format="MMM yyyy"
                      onChange={handleDateRange}
                    />
                  </div>
                  <div>
                    {/* <button
                      className="btn btn-light"
                      onClick={createToggle}
                    >
                      <i className="bx bx-plus me-1"></i>
                      Add New
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={filteredItems ? filteredItems : []}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  paginationPerPage={50}
                  progressPending={pending}
                  paginationRowsPerPageOptions={[10, 30, 50]}
                  paginationComponentOptions={paginationComponentOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {/* <CreateProduct modal={createModal} toggle={createToggle} />
      <UpdateProduct modal={updateModal} toggle={updateToggle} id={id}/>*/}
      <ReturnSale modal={deleteModal} toggle={deleteToggle} id={deleteId} /> 
    </>
  );
};

export default PurchaseReturn;
