import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout";
import DataTable, {createTheme} from "react-data-table-component";
import CreateBrand from "./Form/Create";
import UpdateBrand from "./Form/Update";
import DeleteBrand from "./Form/Delete";
import { getAllBrand, getEditBrand } from "./Redux/brandAction";
import UseModel from "../../hooks/use-Model";

createTheme('dark', {
  background: {
    default: 'transparent',
  },
  text: {
    primary: "primary",
    secondary: 'secondary',
  }
});

const Brand = () => {
  const { brands } = useSelector((state) => state.Brand);
  const { user } = useSelector((state) => state.Auth);
  const createModel = UseModel();
  const updateModel = UseModel();
  const deleteModel = UseModel();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
     {
      name: "Action",
      selector: (row) => (
          <div>
            <button className="btn btn-outline-secondary btn-sm edit m-1" onClick={() => updateModel.updateHandle(getEditBrand,row?.id)} title="Edit">
              <i className="fas fa-pencil-alt"></i>
            </button>
            <button className="btn btn-outline-danger btn-sm delete" onClick={() => deleteModel.deleteHandle(row?.id)} title="cancel">
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        ),
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBrand())
  },[dispatch]);

  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <PageTitleBox title="Brand" />
            </div>
          </div>
        </div>
        <DataTableWrapper data={brands} isAdmin={user?.isAdmin} columns={columns} btnAction={() => createModel.toggle()} />
      </Layout>
    <CreateBrand modal={createModel.modal} toggle={() => createModel.toggle()}/>
    <UpdateBrand modal={updateModel.modal} toggle={() => updateModel.toggle()} id={updateModel.id}/>
    <DeleteBrand modal={deleteModel.modal} toggle={() => deleteModel.toggle()} id={deleteModel.id}/>
    </>
  );
};

const DataTableWrapper = ({ data, columns, btnAction,isAdmin }) => (
  <div className="row">
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <CardHeaderComponents btnAction={btnAction} isAdmin={isAdmin}/>
        </div>
        <div className="card-body">
          <DataTable columns={columns} data={data ?? []} pagination paginationPerPage={50} paginationRowsPerPageOptions={[10, 30, 50]}/>
        </div>
      </div>
    </div>
  </div>
);

const PageTitleBox = ({ title }) => (
  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
    <h4 className="mb-sm-0 font-size-18">{title}</h4>
    <div className="page-title-right">
      <ol className="breadcrumb m-0">
        <li className="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li className="breadcrumb-item active">{title}</li>
      </ol>
    </div>
  </div>
);

const CardHeaderComponents = ({ btnAction,isAdmin }) => (
  <div className="justify-content-between d-flex">
    <div className="d-flex align-items-center">
      <h4 className="card-title">Brand</h4>
    </div>
    <div>
      <button className="btn btn-light" onClick={btnAction}>
        <i className="bx bx-plus me-1"></i>
         Add New
      </button>
    </div>
  </div>
);

export default Brand;