import React, { useEffect } from "react";
import * as Icon from "react-feather";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.Auth);
  useEffect(() => {
    // focus active menu in left sidebar
    if (
      $("#sidebar-menu").length > 0 &&
      $("#sidebar-menu .mm-active .active").length > 0
    ) {
      const activeMenu = $("#sidebar-menu .mm-active .active").offset().top;
      if (activeMenu > 300) {
        $(".vertical-menu .simplebar-content-wrapper").animate(
          {
            scrollTop: activeMenu - 300,
          },
          "slow"
        );
      }
    }
  }, []);
  return (
    <>
      {/* <!-- ========== Left Sidebar Start ========== --> */}
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {/* <!--- Sidemenu --> */}
          <div id="sidebar-menu">
            {/* <!-- Left Menu Start --> */}
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title" data-key="t-menu">
                Menu
              </li>

              <li className={pathname === "/" ? "mm-active" : ""}>
                <Link to="/">
                  <Icon.Home />
                  <span data-key="t-dashboard">Data Entry</span>
                </Link>
              </li>
              {/* <li className={pathname.includes("setting") ? "mm-active" : ""}>
                <Link to="/setting/institute" className="has-arrow">
                  <Icon.Settings />
                  <span data-key="t-setting">General Setting</span>
                </Link>
                <ul
                  className={`mm-collapse sub-menu ${
                    pathname.includes("setting") ? "mm-show" : ""
                  }`}
                >
                  <li
                    className={
                      pathname === "/setting/institute" ? "mm-active" : ""
                    }
                  >
                    <Link to="/setting/institute" data-key="t-institute">
                      Institute Profile
                    </Link>
                  </li>
                  <li
                    className={pathname === "/setting/fee" ? "mm-active" : ""}
                  >
                    <Link to="/setting/fee" data-key="t-fee">
                      Fee
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === "/setting/grading" ? "mm-active" : ""
                    }
                  >
                    <Link to="/setting/grading" data-key="t-grading">
                      Grading
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === "/setting/account" ? "mm-active" : ""
                    }
                  >
                    <Link to="/setting/account" data-key="t-account">
                      Account
                    </Link>
                  </li>
                </ul>
              </li> */}
               <li className={pathname === "/product" ? "mm-active" : ""}>
                <Link to="/product">
                  <Icon.Edit />
                  <span data-key="t-dashboard">Product</span>
                </Link>
              </li>
              <li className={pathname === "/brand" ? "mm-active" : ""}>
                <Link to="/brand">
                  <Icon.Book />
                  <span data-key="t-dashboard">Brands</span>
                </Link>
              </li>
              <li className={pathname === "/type" ? "mm-active" : ""}>
                <Link to="/type">
                  <Icon.Book />
                  <span data-key="t-dashboard">Type</span>
                </Link>
              </li>
              {/* <li className={pathname === "/stockInHand" ? "mm-active" : ""}>
                <Link to="/stockInHand">
                  <Icon.List />
                  <span data-key="t-dashboard">Stock in Hand</span>
                </Link>
              </li>
              
              <li className={pathname === "/purchase" ? "mm-active" : ""}>
                <Link to="/purchase">
                  <Icon.Package />
                  <span data-key="t-dashboard">Purchase</span>
                </Link>
              </li> */}
              <li className={pathname === "/sale-return" ? "mm-active" : ""}>
                <Link to="/sale-return">
                  <Icon.AlertOctagon />
                  <span data-key="t-dashboard">Sale Return</span>
                </Link>
              </li>
               <li className={pathname === "/purchase-return" ? "mm-active" : ""}>
                <Link to="/purchase-return">
                  <Icon.AlertOctagon />
                  <span data-key="t-dashboard">Purchase Return</span>
                </Link>
              </li>
              <li className={pathname === "/sale-report" ? "mm-active" : ""}>
                <Link to="/sale-report">
                  <Icon.AlertOctagon  />
                  <span data-key="t-dashboard">Monthly Report</span>
                </Link>
              </li>
              {user?.isAdmin && <li className={pathname === "/cogs-report" ? "mm-active" : ""}>
                <Link to="/cogs-report">
                  <Icon.File />
                  <span data-key="t-dashboard">COGS Report</span>
                </Link>
              </li>}
              {/* <li className={pathname.includes("teachers") ? "mm-active" : ""}>
                <Link to="/teachers/view">
                <Icon.File />
                  <span data-key="t-dashboard">COGS Summary</span>
                </Link>
              </li> */}

              
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
