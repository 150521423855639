import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
// import Admin from "./components/Home/Admin";
import Login from "./components/Auth/Login";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadUser } from "./components/Auth/Redux/authAction";
import Signup from "./components/Auth/Signup";
import Brand from "./components/Brand";
import Type from "./components/Type";
import Product from "./components/Product";
import StockInHand from "./components/StockInHand";
import Purchase from "./components/Purchase";
import DataEntry from "./components/DataEntry";
import SaleReport from "./components/SaleReport";
import SaleReturn from "./components/SaleReturn";
import COGSReport from "./components/COGSReport";
import PurchaseReturn from "./components/PurchaseReturn";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<DataEntry />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/signup" element={<Signup />}></Route>
      <Route path="/brand" element={<Brand />}></Route>
      <Route path="/type" element={<Type />}></Route>
      <Route path="/product" element={<Product />}></Route>
      <Route path="/stockInHand" element={<StockInHand />}></Route>
      <Route path="/purchase" element={<Purchase />}></Route>
      <Route path="/sale-report" element={<SaleReport/>}></Route>
      <Route path="/sale-return" element={<SaleReturn/>}></Route>
      <Route path="/purchase-return" element={<PurchaseReturn/>}></Route>
      <Route path="/cogs-report" element={<COGSReport/>}></Route>
    </>
  )
);

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated && !user) {
      dispatch(loadUser());
    }
  }, [user, isAuthenticated, dispatch]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
