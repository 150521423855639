import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { loginUser } from "../Redux/authAction";
import { Formik } from "formik";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.Auth);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmitForm = async (values) => {
    const data = new FormData();
    data.append("email", values.email);
    data.append("password", values.password);
    console.log(data);
    dispatch(loginUser(data)).then((res) => {
      if (res.data.status === true) {
        navigate("/");
      } else {
        setError(res.data.message);
      }
    });
  };
  return !isAuthenticated ? (
    <div className="auth-page">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h5 className="mb-0">Welcome Back !</h5>
                      <p className="text-muted mt-2">
                        Sign in to continue to Software.
                      </p>
                    </div>
                    {error && (
                      <div className="alert alert-danger " role="alert">
                        {error}
                      </div>
                    )}
                    <Formik
                      initialValues={{ email: "", password: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                          errors.email = "Required";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address";
                        }
                        if (!values.password) {
                          errors.password = "Required";
                        }
                        return errors;
                      }}
                      onSubmit={handleSubmitForm}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <div className="justify-content-center row">
                          <form
                            onSubmit={handleSubmit}
                            className="custom-form mt-4 pt-2 col-xxl-3 col-lg-4 col-md-5"
                          >
                            <div className="mb-3">
                              <label className="form-label">Email</label>
                              <input
                                className="form-control"
                                placeholder="Enter Email"
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <div className="m-1 text-danger">
                                {errors.email && touched.email && errors.email}
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <label className="form-label">Password</label>
                                </div>
                                <div className="flex-shrink-0">
                                  <div className="">
                                    <a
                                      href="forgot-password"
                                      className="text-muted"
                                    >
                                      Forgot password?
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div className="input-group auth-pass-inputgroup">
                                <input
                                  className="form-control"
                                  placeholder="Enter password"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <button
                                  className="btn btn-light ms-0"
                                  type="button"
                                  onClick={() => {
                                    setShowPassword((prev) => !prev);
                                  }}
                                >
                                  <i
                                    className={
                                      showPassword
                                        ? "mdi mdi-eye-outline"
                                        : "mdi mdi-eye-off-outline"
                                    }
                                  ></i>
                                </button>
                              </div>
                              <div className="m-1 text-danger">
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="remember-check"
                                  >
                                    Remember me
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>

                            <div className="row">
                              
                                <p className="text-center">
                                  Not a Member?{" "}
                                  <Link to="/signup">Sign Up</Link>{" "}
                                </p>
                              
                            </div>
                          </form>
                        </div>
                      )}
                    </Formik>

                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      Copyright © {new Date().getFullYear()} , Software. All
                      Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/" replace />
  );
};

export default Login;
