import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  types: null,
  selectedType: null,
  loading: false,
};

const typeSlice = createSlice({
  name: "Type",
  initialState,
  reducers: {
    catchAction: (state, action) => {
      state.loading = action.payload;
    },
    setTypes: (state, action) => {
      state.types = action.payload;
    },
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
    },
    storeType: (state, action) => {
      state.types = [...state.types, action.payload];
    },
    updateType: (state, action) => {
      state.types =
        state.types &&
        state.types.map((obj) =>
          obj.id === action.payload?.id ? action.payload : obj
        );
    },
    deleteType: (state, action) => {
      state.types =
        state.types &&
        state.types.filter((obj) => obj.id !== action.payload);
    },
    resetData: () => {
      return initialState;
    }
  },
});

export default typeSlice;

export const typeActions = typeSlice.actions;
