import { useState } from "react";
import { useDispatch } from "react-redux";

const UseModel = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [id, setId] = useState();
  const toggle = () => setModal(!modal);
  const updateHandle = (updateFunction, id) => {
    setId(id);
    dispatch(updateFunction(id)).then((res) => {
      if (res.data.status === true) {
        toggle();
      } else {
      }
    });
  };

  const deleteHandle = (id) => {
    setId(id);
    toggle();
  }

  return {
    modal,
    toggle,
    updateHandle,
    deleteHandle,
    id,
  };
};

export default UseModel;
