import React from 'react';
import { Spinner } from 'reactstrap';

const FormButtons = ({toggle,loading,btn=""}) => {
    return (
        <div className="float-end mb-3">
        <button
          type="button"
          className="btn btn-dark"
          onClick={toggle}
          style={{marginRight: "8px"}}
        >
          Close
        </button>
        <button type="submit" className={btn === 'delete' ? "btn btn-danger" : "btn btn-primary"} disabled={loading}>
          {loading && (
            <Spinner
              style={{ marginRight: "5px" }}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {btn === 'delete' ?  "Return" : "Save changes"}
        </button>
      </div>
    );
}

export default FormButtons;
