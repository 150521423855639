import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { exportSaleStock, getPurchaseReport, getSaleReport } from "../Product/Redux/productAction";
import { productActions } from "../Product/Redux/productSlice";
import FormSelectField from "../../UI/FormSelectField/Index";
// import { productActions } from "../Product/Redux/productSlice";

createTheme("dark", {
  background: {
    default: "transparent",
  },
  text: {
    primary: "primary",
    secondary: "secondary",
  },
});

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="text"
      style={{ height: "37px" }}
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button className="btn btn-info m-1" type="button" onClick={onClear}>
      Remove
    </button>
  </>
);

const SaleReport = () => {
  const { StockInHandDetail, column } = useSelector((state) => state.Product);
  const [exportShow, setExportShow] = useState(false);
  const [type, setType] = React.useState({ label: "Sale", value: "sale" });
  const [pending, setPending] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  // const handleEditorField = (e,row,item) => {
  //   dispatch(productActions.updateStockInHand({id: row.id,item,value: e.target.value}))
  // }
  
  console.log(StockInHandDetail);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    // {
    //   name: "Price",
    //   selector: (row) => <div>${row.price}</div>,
    //   sortable: true,
    // },
  ];

  const test = column
    ? column.map((item) => {
        return {
          name: item,
          selector: (row) => row[item] ?? 0,
          sortable: true,
          
        };
      })
    : [];

  const results = [...columns, ...test];
  console.log(results);

  const dispatch = useDispatch();

  const handleDateRange = (e) => {
    if (e != null) {
      const starting = new Date(e[0]);
      const ending = new Date(e[1]);

      const startingDate = starting.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });
      const endingDate = ending.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });

      localStorage.setItem('starting_date',startingDate);
      localStorage.setItem('ending_date',endingDate);

      const data = new FormData();
      data.append("starting_date", startingDate);
      data.append("ending_date", endingDate);

      setExportShow(true);

      dispatch(getSaleReport(data));

      console.log(startingDate, endingDate);
    }
  };

  const exportData = () => {
    const data = new FormData();
    data.append("starting_date",localStorage.getItem('starting_date'));
    data.append("ending_date",localStorage.getItem('ending_date'))
    if (type.value === "purchase") {
      dispatch(exportSaleStock(data,'export-purchase-report','purchase-report ',localStorage.getItem('starting_date'),localStorage.getItem('ending_date')));
    } else {
      dispatch(exportSaleStock(data,'export-sale-report','sale-report ',localStorage.getItem('starting_date'),localStorage.getItem('ending_date')));
    }
    
    
  }

  useEffect(() => {
    return () => {
      dispatch(productActions.resetData());
      localStorage.removeItem('starting_date');
      localStorage.removeItem('ending_date');
    };
  }, [dispatch]);

  const handleType = (selected) => {
    setType(selected);
    const data = new FormData();
    data.append("starting_date", localStorage.getItem("starting_date"));
    data.append("ending_date", localStorage.getItem("ending_date"));
    if (selected.value === "purchase") {

      setPending(true);
      dispatch(getPurchaseReport(data)).then((res) => {
        setPending(false);
      });
    } else if(selected.value === "sale") {
      setPending(true);
      dispatch(getSaleReport(data)).then((res) => {
        setPending(false);
      });
    }
    console.log(selected.value);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const filteredItems =
    StockInHandDetail &&
    StockInHandDetail.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  //   useEffect(() => {
  //     dispatch(getAllProduct());
  //   }, [dispatch]);
  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="page-title-box d-sm-flex align-items-center
                    justify-content-between"
              >
                <h4 className="mb-sm-0 font-size-18">Monthly Report</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Report</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="justify-content-between d-flex">
                  <div className="d-flex align-items-center">
                    <DateRangePicker
                      format="MMM yyyy"
                      onChange={handleDateRange}
                    />
                    <div style={{ width: "16rem", marginLeft: "8px" }}>
                      <FormSelectField
                        value={type}
                        options={[
                          { label: "Sale", value: "sale" },
                          // { label: "Sale Return", value: "sale_return" },
                          { label: "Purchase", value: "purchase" },
                          // {
                          //   label: "Purchase Return",
                          //   value: "Purchase_return",
                          // },
                        ]}
                        onChange={(selected) => handleType(selected)}
                      />
                    </div>
                  </div>
                  
                  <div>
                  {exportShow && <button
                        className="btn btn-success"
                        onClick={exportData}
                      >
                        Export
                      </button>}
                    {/* <button
                      className="btn btn-light"
                      onClick={createToggle}
                    >
                      <i className="bx bx-plus me-1"></i>
                      Add New
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={results}
                  data={filteredItems ? filteredItems : []}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  paginationPerPage={50}
                  progressPending={pending}
                  paginationRowsPerPageOptions={[10, 30, 50]}
                  paginationComponentOptions={paginationComponentOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {/* <CreateProduct modal={createModal} toggle={createToggle} />
      <UpdateProduct modal={updateModal} toggle={updateToggle} id={id}/>
      <DeleteProduct modal={deleteModal} toggle={deleteToggle} id={deleteId} /> */}
    </>
  );
};

export default SaleReport;
